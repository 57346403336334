
// axios
import axios from 'axios'

// import vue
import { Options, Vue } from "vue-class-component";

@Options({
  name: 'Home',
  data(){
    return{
      config: [],
      loading: true,
      token: String,
      paymentId: Number,
      total: 0,
      paymentMethods:[
        {
          name: 'Visa',
          logo: '/img/visa-icon.svg',
          options:[
            {
              id: 1,
              name: 'Cédito'
            },
            {
              id: 31,
              name: 'Débito'
            }
          ]
        },
        {
          name: 'Mastercard',
          logo: '/img/mastercard-icon.svg',
          options:[
            {
              id: 15,
              name: 'Crédito'
            },
            {
              id: 104,
              name: 'Débito'
            },
            {
              id: 106,
              name: 'Maestro'
            }
          ]
        },
        {
          name: 'Cabal',
          logo: '/img/cabal-icon.svg',
          options:[
            {
              id: 107,
              name: 'Débito'
            }
          ]
        }
      ],
      selectedPaymentMethod: [],
      selectedPaymentOption: 0
    }
  },
  mounted(){
    // set payment ID
    this.paymentId = this.$route.query.order

    //set token
    this.token = this.$route.query.token

    if(!this.token){
      this.token = 'c2VydmVyOjh2R0h3eGdVYXk4dEJ5cHVRcUExcnFvMDEweDYzUXk3'
    }
    
    if(this.paymentId && this.token){
      //check health and get payment Info
      Promise.resolve(this.getConfig()).then( ()=>{
          Promise.resolve(this.checkHealth( ()=>{
            this.getPaymentInfo(this.paymentId)
          }))
        }
      )
    }else{
      this.$router.push('/warning')
    }
  },
  methods: {
    async getConfig(){
      await axios.get("/config.json")
      .then((response) => {
        localStorage.setItem('config', JSON.stringify(response.data) )
        this.config = response.data
      })
    },
    async checkHealth(callback: ()=> void){
      await axios.get('https://live.decidir.com/api/v2/healthcheck')
      .then(() => {
        callback()
      })
      .catch( error => {
        console.log(error)
      })
    },
    async getPaymentInfo(id: number){
      await axios.get(this.config.paymentGetEndpoint+id+'?access_token='+this.token)
      .then((response) => {
        this.total = response.data.data[this.config.totalField]
        this.loading = false
      })
      .catch( error => {
        console.log(error)
      })
    },
    setPaymentMethod( method: any[any], element: HTMLElement ){
      //set selected payment option to initial
      this.selectedPaymentOption = 0

      //remove border on previous selected option
      if( this.$refs.cardOption ){
        this.$refs.cardOption.forEach( (item: any) => {
          item.removeAttribute('style')
        });
      }

      //remove border on previous selected card
      this.$refs.card.forEach( (item: any) => {
        item.removeAttribute('style')
      });

      //add border to selected card
      element.style.borderColor = this.config.color

      //set selected payment method
      this.selectedPaymentMethod = method
    },
    setPaymentOption( option: number, element: HTMLElement ){
      //remove border on previous selected option
      this.$refs.cardOption.forEach( (item: any) => {
        item.removeAttribute('style')
      });

      //add border to selected option
      element.style.borderColor = this.config.color

      //set selected payment option
      this.selectedPaymentOption = option
    },
    pay(){
      if(this.selectedPaymentOption > 0){
        this.$router.push('/pay?id='+this.paymentId+'&pmid='+this.selectedPaymentOption)
      }
    }
  }
}
)
export default class Home extends Vue {}
